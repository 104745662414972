.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    display: inline-block;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}