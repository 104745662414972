.shopNameText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  .slick-prev {
    color: red; /* Set the color to red */
  }
  
  .slick-next {
    color: blue; /* Set the color to blue */
  }


  /* .carousel .thumbs-wrapper {
    height: 100px;
    width: 100px;
  }
  .carousel .thumb {
    height: 100px;
    width: 100px;
  } */
  